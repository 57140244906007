import ImageLogo from "../../assets/images/gala-2023/10th-anniversary-logo.png";

export default function Banner() {
  return (
    <>
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="true" />
      <link
        href="https://fonts.googleapis.com/css2?family=Great+Vibes&family=Staatliches&display=swap"
        rel="stylesheet"
      />
      <div
        className="container mx-auto py-4 flex flex-col items-center border-2 border-gold rounded-md tracking-wider"
        style={{ fontFamily: "Staatliches" }}
      >
        <img src={ImageLogo} className="h-20 lg:h-28" alt="Gateway Industry 10th Anniversary Logo" />
        <h1 className="w-full text-center text-3xl lg:text-5xl text-gold bg-cream mt-4 p-4">
          Gateway Industry 10th Anniversary Gala
        </h1>
        <h2
          className="text-3xl lg:text-5xl text-center text-gold my-6 tracking-normal px-4"
          style={{ fontFamily: "Great Vibes, cursive" }}
          title="A Decade of Empowerment: Transforming Lives and Advancing Futures"
        >
          A Decade of Empowerment: Transforming Lives and Advancing Futures
        </h2>
        <p className="w-full text-center text-xl lg:text-4xl text-gray-600 bg-cream p-4">
          Sunday, October 29, 2023, 5:00pm
        </p>
        <p className="text-2xl lg:text-3xl text-center text-gold mt-4 px-4">
          <a href="https://grandvenue.com/">Grand Venue</a>
        </p>
        <p className="text-lg lg:text-2xl text-center text-gray-600 tracking-normal lg:tracking-wide px-4">
          <a href="https://maps.app.goo.gl/jrjs4ps1iTmAAB7E7">3070 Los Feliz Blvd, Los Angeles, CA 90039</a>
        </p>
      </div>
    </>
  );
}
