import { useState } from "react";
import { Alert } from "@material-tailwind/react";

import sendEmail from "../../utils/sendEmail";

export default function Subscribe() {
  const [mailingNotification, setMailingNotification] = useState(false);
  const [mailingSuccess, setMailingSuccess] = useState(false);

  const handleSubmit = (e) => {
    sendEmail(e).then((mailingResult) => {
      setMailingNotification(mailingResult.notification);
      setMailingSuccess(mailingResult.success);
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <input name="subject" type="hidden" value="Mailing List Subscription Request" />
      <h2 className="font-semibold">Subscribe To Our Mailing List</h2>
      {mailingNotification ? (
        <Alert variant="filled" color={mailingSuccess ? "green" : "red"} className="font-semibold mt-1 rounded-md">
          {mailingNotification}
        </Alert>
      ) : (
        ""
      )}
      <input
        type="email"
        name="email"
        required={true}
        className="w-full py-3 my-2 rounded-md font-semibold text-main placeholder:text-main"
        placeholder="Your email"
      />
      <button type="submit" className="w-full py-4 font-semibold text-white bg-black rounded-md text-base">
        Subscribe
      </button>
    </form>
  );
}
