import { useState } from "react";
import { Alert, Input, Textarea } from "@material-tailwind/react";

import sendEmail from "../../utils/sendEmail";

export default function ContactForm({ emailSubject, initialMessageValue }) {
  const [mailingNotification, setMailingNotification] = useState(false);
  const [mailingSuccess, setMailingSuccess] = useState(false);
  const [messageValue, setMessageValue] = useState(initialMessageValue);

  if (!emailSubject) {
    emailSubject = "Contact Request";
  }

  const handleTextareaChange = (e) => {
    setMessageValue(e.target.value);
  };

  const handleSubmit = (e) => {
    sendEmail(e).then((mailingResult) => {
      setMailingNotification(mailingResult.notification);
      setMailingSuccess(mailingResult.success);
    });
  };

  return (
    <form className="flex flex-col px-4 lg:px-6 space-y-4" onSubmit={handleSubmit}>
      {mailingNotification ? (
        <Alert variant="outlined" color={mailingSuccess ? "green" : "red"} className="font-semibold">
          {mailingNotification}
        </Alert>
      ) : (
        ""
      )}
      <input name="subject" type="hidden" value={emailSubject} />
      <Input name="name" variant="standard" label="Name:" color="gray" required className="px-2" />
      <Input name="email" variant="standard" label="Email:" color="gray" required className="px-2" />
      <Input name="phone" variant="standard" label="Phone:" color="gray" required className="px-2" />
      <Textarea
        name="message"
        variant="standard"
        label="Message:"
        value={messageValue}
        color="gray"
        onChange={handleTextareaChange}
        className="text-gray bg-transparent border-t-0 border-l-0 border-r-0 border-b-white focus:border-b-white placeholder:text-gray focus:outline-none focus:ring-0 px-2"
      />
      <button type="submit" className="block py-4 font-semibold text-white bg-gray-800 rounded-md">
        Send Message
      </button>
    </form>
  );
}
