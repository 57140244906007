import { Card, CardHeader, CardBody, Typography } from "@material-tailwind/react";
import Slider from "react-slick";

import BannerImage from "./components/bannerImage";
import BigImg from "../assets/img/homeSlider/Retraining-teachers-_2_-transformed.webp";
import Img1 from "../assets/img/teachers/193767364_2973043516309677_9030891778394258361_n.webp";
import Img2 from "../assets/img/teachers/194375913_2973043432976352_4641713911180691730_n.webp";
import Img3 from "../assets/img/teachers/194464813_2973043456309683_6686650733424060980_n.webp";
import Img4 from "../assets/img/teachers/194838700_2973043266309702_2296291747461366475_n.webp";
import Img5 from "../assets/img/teachers/194886597_2973043329643029_3415358862962056767_n.webp";
import Img6 from "../assets/img/teachers/194987393_2973043352976360_6961321566284362204_n.webp";
import Img7 from "../assets/img/teachers/195478575_2973043359643026_7465308408708033446_n.webp";
import Img8 from "../assets/img/teachers/195475830_2973043286309700_2621855776820878158_n.webp";
import Img9 from "../assets/img/teachers/196284857_2973043399643022_2064122136870941601_n.webp";

export default function TeacherRetrainingProject() {
  const settings = {
    autoplay: true,
    autoplaySpeed: 2000,
    dots: false,
    fade: true,
    infinite: true,
    slidesToScroll: 1,
    slidesToShow: 1,
    speed: 500,
  };

  const imageList = [
    {
      image: Img1,
      title: "Teacher Image",
    },
    {
      image: Img2,
      title: "Teacher Image",
    },
    {
      image: Img3,
      title: "Teacher Image",
    },
    {
      image: Img4,
      title: "Teacher Image",
    },
    {
      image: Img5,
      title: "Teacher Image",
    },
    {
      image: Img6,
      title: "Teacher Image",
    },
    {
      image: Img7,
      title: "Teacher Image",
    },
    {
      image: Img8,
      title: "Teacher Image",
    },
    {
      image: Img9,
      title: "Teacher Image",
    },
  ];

  const list = [
    {
      text: ' One hundred teachers from the Martakert, Askeran and Martuni regions in Artsakh and Armenia participated in a week-long training program called "Teacher Capacity Development in the Post-War Period".',
    },
    {
      text: " The purpose of the project was to equip teachers with the necessary skills to effectively work and provide psychological support to the children of families affected by the Artsakh War of 2020.",
    },
    {
      text: "The project was sponsored by Gateway Industry and the program was developed and implemented by the Ministry of Education and Science of the Republic of Artsakh with the support of the Ministry of Labor, Social Affairs and Migration of the Republic of Artsakh and the Ministry of Territorial Administration of the Republic of Artsakh.",
    },
  ];

  return (
    <>
      <BannerImage image={BigImg} />
      <div className="max-w-screen-xl py-24 mx-auto max-xl:px-6">
        <Card className="static !z-0 flex-row w-full bg-transparent shadow-none max-md:flex-col">
          <CardHeader
            shadow={false}
            floated={false}
            className="static z-0 m-0 bg-transparent rounded-r-none md:w-1/2 shrink-0"
          >
            <Slider {...settings} className="p-0 m-0">
              {imageList &&
                imageList.map((item, index) => {
                  return (
                    <div className="p-0 m-0" key={index}>
                      <img src={item.image} alt="" />
                    </div>
                  );
                })}
            </Slider>
          </CardHeader>
          <CardBody className="flex flex-col justify-center">
            <Typography variant="h4" color="blue-gray" className=" text-main">
              Teacher Retraining Project
            </Typography>
            {list &&
              list.map((item, index) => {
                return (
                  <Typography key={index} color="gray" className="mb-8 font-normal text-black/60">
                    {item.text}
                  </Typography>
                );
              })}
          </CardBody>
        </Card>
      </div>
    </>
  );
}
