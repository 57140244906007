import { MdOutlineEmail, MdOutlineLocationOn } from "react-icons/md";
import { BsInstagram } from "react-icons/bs";

import ContactForm from "./components/contactForm";
import Subscribe from "./components/subscribe";

export default function Contact() {
  const data = [
    {
      icon: <MdOutlineLocationOn size="1.5rem" className="shrink-0" />,
      content: <a href="https://goo.gl/maps/NsPmgB9baH5bBqzY9">1812 West Burbank Boulevard #295, Burbank, CA 91506</a>,
    },
    {
      icon: <MdOutlineEmail size="1.5rem" className="shrink-0" />,
      content: <a href="mailto:gatewayassn@gmail.com">gatewayassn@gmail.com</a>,
    },
    {
      icon: <BsInstagram size="1.5rem" className="shrink-0" />,
      content: <a href="https://www.instagram.com/gatewaynpo">Instagram</a>,
    },
  ];

  return (
    <div className="container mx-auto p-4 lg:px-0">
      <h1 className="mb-4 text-xl lg:text-2xl text-center text-main font-bold">Contact Us</h1>
      <div className="flex flex-col lg:flex-row gap-x-4">
        <div className="lg:w-1/2 rounded-lg bg-white py-4 mb-4 lg:mb-0">
          <ContactForm />
        </div>
        <div className="lg:w-1/2 rounded-lg bg-main py-4 lg:pt-12 px-4 lg:px-6 max-lg:space-y-6 text-white lg:text-lg flex flex-col justify-between">
          {data.map((item, index) => {
            return (
              <div key={index} className="flex items-center gap-x-2">
                {item.icon}
                {item.content}
              </div>
            );
          })}
          <Subscribe />
        </div>
      </div>
    </div>
  );
}
