import BannerImage from "./components/bannerImage";
import CardImage from "./components/cardImage";

import Img1 from "../assets/img/2.jpg";
import Img2 from "../assets/img/1.jpg";
import ImgBanner from "../assets/img/homeSlider/New-path-5-transformed.webp";

export default function GatewayToEducationNewPath() {
  const list = [
    {
      image: Img1,
      flex: "flex-row",
      title: "New Path",
      text: 'The "New Path" program provides vocational training to family members of fallen soldiers of the 2020 Artsakh war. The project is financed by Gateway Industry Inc. and the World Council of Churches Armenia Inter-Church Charitable Round Table Foundation. As part of the first round of the program, held from December 2021 to May 2022, 54 participants - mostly wives and mothers of fallen soldiers - received training in their chosen vocational field. Seventeen different fields of study were offered to the participants, some of which included: graphic design, web design, pastry chef, cosmetology, foreign language studies, and others.',
    },
    {
      image: Img2,
      flex: "flex-row-reverse",
      title: "",
      text: "Upon graduation in June 2022, 26 of the 54 participants had found employment in their respective fields of study. The program was developed and funded with the intention of creating long-term socio-economic impact among women who have lost a male member of their household. These women are provided with the opportunity to acquire education, to enter the workforce and be able to provide for their families. Additionally, the program serves a very important objective of providing psychological assistance to these women. The educational program and employment opportunities provide psychological support and allow these women to integrate back into society. In September 2022, the second round of the project began, with the participation of 37 beneficiaries.",
    },
  ];

  return (
    <>
      <BannerImage image={ImgBanner} />

      <div className="max-w-screen-xl py-24 mx-auto max-xl:px-6">
        {list &&
          list.map((item, index) => {
            return <CardImage image={item.image} flex={item.flex} title={item.title} text={item.text} key={index} />;
          })}
      </div>
    </>
  );
}
