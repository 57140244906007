import CardText from "./components/cardText";

export default function GatewayTechnologies() {
  const list = [
    {
      text: "With the targeted purpose of contributing to the development of science and technology in Armenia, Gateway Industry provided the seed funding for Masis Gateway and Sis Gateway Artificial Intelligence ('AI') Laboratories at the Foundation of Armenian Science and Technology ('FAST'). These labs are equipped with state-of-the-art equipment to allow for the successful implementation of various computation-intensive R&D projects, to train machine learning prototype models and undertake other AI training initiatives. These labs facilitate the establishment of AI training and expertise in Armenia, which represents an important strategic step towards the development of Armenia's AI capabilities. Since the establishment of the labs in early 2021, 397 students have received AI training at FAST Creative Campus. The students undergo a highly competitive selection process and participate in a six-month long rigorous training program.",
    },
  ];

  return <CardText title="GATEWAY TO SCIENCE AND TECHNOLOGY: Masis Gateway and Sis Gateway AI Labs" data={list} />;
}
