import BannerImage from "./components/bannerImage";

import BannerImg from "../assets/img/clinic/Bulding-gateways-to-health-new.png";
import ClinicImage1 from "../assets/img/clinic/photo_2024-04-11_14-26-28.jpg";
import ClinicImage2 from "../assets/img/clinic/photo_2024-04-11_14-26-49.jpg";
import ClinicImage3 from "../assets/img/clinic/photo_2024-04-11_14-27-00.jpg";

import CardText from "./components/cardText";

export default function GatewayClinic() {
  const data = {
    title: "Gateway Health Clinic",
    data: [
      {
        text: "Gateway Health Clinic is an innovative clinic nestled in the heart of Ararat province, Armenia. This endeavor marks a significant milestone as the first diaspora-sanctioned and operated non-profit comprehensive primary health clinic in the region, set to revolutionize healthcare accessibility.",
      },

      {
        text: "As a heartfelt token of appreciation and gratitude, the clinic will extend complimentary services to our esteemed veterans, recognizing their unwavering dedication and service to our homeland.",
      },

      {
        text: "At the moment, all the strength and energy of our organization is directed to the launch of this clinic. Progress has been substantial over the past quarter, with notable advancements in securing necessary approvals and permits, alongside the establishment of key partnerships. Our team is diligently engaged in meticulously planning the design phase to ensure the clinic meets the highest standards of quality and care.",
      },

      {
        text: "Excitingly, progress is underway with the project's approval process by state authorities. With these administrative procedures nearing completion, we eagerly anticipate transitioning to the commencement of construction. Our biggest wish is to see the hospital operating as soon as possible, fulfilling its noble purpose of providing exemplary healthcare services to our veterans and the community.",
      },

      {
        text: "With dedication and compassion, we remain steadfast in our mission to deliver state-of-the-art healthcare services, embodying our commitment to the well-being of all.",
      },
    ],
    images: [
      {
        image: ClinicImage1,
        alt: "Gateway Clinic Image",
      },
      {
        image: ClinicImage2,
        alt: "Gateway Clinic Image",
      },
      {
        image: ClinicImage3,
        alt: "Gateway Clinic Image",
      },
    ],
  };

  return (
    <>
      <BannerImage image={BannerImg} />
      <div className="max-w-screen-xl py-12 mx-auto max-xl:px-6">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
          {data.images &&
            data.images.map((item, index) => {
              return (
                <div key={index}>
                  <img src={item.image} alt={item.alt} />
                </div>
              );
            })}
        </div>
        <CardText title={data.title} data={data.data} />
      </div>
    </>
  );
}
