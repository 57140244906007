import CountUp from "react-countup";

import BeneficiariesImage from "../../assets/img/beneficiaries.png";
import CommunitiesImage from "../../assets/img/communities.png";
import InnovateImage from "../../assets/img/innovated-infrastructures.png";
import ProjectsImage from "../../assets/img/projects.png";
import ScholarshipsImage from "../../assets/img/scholarships.png";

export default function ProjectsCount() {
  const list = [
    {
      image: BeneficiariesImage,
      title: "BENEFICIARIES",
      duration: 8,
      end: 7000,
    },
    {
      image: ProjectsImage,
      title: "PROJECTS",
      duration: 3,
      end: 25,
    },
    {
      image: CommunitiesImage,
      title: "COMMUNITIES",
      duration: 4,
      end: 97,
    },
    {
      image: InnovateImage,
      title: "INFRASTRUCTURE RENOVATION",
      duration: 1,
      end: 6,
    },
    {
      image: ScholarshipsImage,
      title: "SCHOLARSHIPS",
      duration: 1,
      end: 8,
    },
  ];

  return (
    <div className="w-full py-12 bg-center bg-cover sm:py-36 max-xl:px-6 bg-projects-count-bg">
      <div className="flex items-end max-w-screen-xl mx-auto max-md:flex-wrap">
        {list.map((item, index) => {
          return (
            <div className="w-full sm:w-1/2 lg:w-1/5" key={index}>
              <div className="flex justify-center h-52">
                <img src={item.image} className="max-h-[100%]" alt="" />
              </div>
              <div className="text-xl text-center text-white h-14">
                {item.title}
              </div>
              <div className="mt-2 text-4xl font-bold text-center text-white">
                <CountUp
                  start={0}
                  duration={item.duration}
                  delay={0.3}
                  end={item.end}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
