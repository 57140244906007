import { Link } from "react-router-dom";
import Slider from "react-slick";

import Banner from "../../components/gala/banner";

import Slider1Image from "../../assets/img/homeSlider/New-path-5-transformed.webp";
import Slider2Image from "../../assets/img/homeSlider/Rehabilitation-1-transformed.webp";
import Slider3Image from "../../assets/img/homeSlider/t-transformed.webp";
import Slider4Image from "../../assets/img/homeSlider/tumo-transformed.webp";
import Slider5Image from "../../assets/img/homeSlider/Website-visual-2-55F9BEDl2-transformed.webp";
import Slider6Image from "../../assets/img/homeSlider/Retraining-teachers-_2_-transformed.webp";

import KashImage from "../../assets/img/Khash.png";
import NewYearImage from "../../assets/img/Happy-New-Year-for-website.png";

export default function HomeSlider() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    fade: true,
    autoplaySpeed: 2000,
  };

  const imageList = [
    { image: Slider1Image, title: "New-path" },
    { image: Slider2Image, title: "Rehabilitation" },
    { image: Slider3Image, title: "Tumo" },
    { image: Slider4Image, title: "Tumo Transformed" },
    { image: Slider5Image, title: "Visual" },
    { image: Slider6Image, title: "Teachers" },
    // { image: KashImage, title: "Kash Image" },
    // { image: NewYearImage, title: "New Year Image" },
  ];

  return (
    <>
      {/* <div className="pt-2 pb-4 mx-auto bg-white container-fluid max-lg:px-4"> */}
      {/* <Link to={"/gala"}>
          <Banner />
        </Link> */}
      {/* <img src={KashImage} className="w-full" /> */}
      {/* </div> */}
      <div className="w-full Right__Slider">
        <Slider {...settings}>
          {imageList &&
            imageList.map((item, index) => {
              return (
                <div className="max-sm:h-48" key={index}>
                  <img
                    src={item.image}
                    className="w-full h-full"
                    alt={item.title}
                  />
                </div>
              );
            })}
        </Slider>
      </div>
    </>
  );
}
