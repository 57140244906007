import { List, ListItem, ListItemPrefix, Card } from "@material-tailwind/react";

import { MdOutlineEmail, MdOutlineLocationOn, MdOutlinePhone } from "react-icons/md";
import { BsInstagram } from "react-icons/bs";

export default function Footer() {
  const listUsa = [
    {
      image: <MdOutlineLocationOn />,
      href: "https://goo.gl/maps/NsPmgB9baH5bBqzY9",
      text: "1812 W. Burbank Blvd. #295, Burbank, CA 91506",
    },
    {
      image: <MdOutlineEmail />,
      href: "mailto:gatewayassn@gmail.com",
      text: "gatewayassn@gmail.com",
    },
    {
      image: <BsInstagram />,
      href: "https://www.instagram.com/gatewaynpo",
      text: "Instagram",
    },
  ];

  const listAm = [
    {
      image: <MdOutlineLocationOn />,
      href: "https://goo.gl/maps/EUfiJ5PM56hmB96z7",
      text: "23/3 Yervand Kochar Street, Yerevan, Armenia",
    },
    {
      image: <MdOutlineEmail />,
      href: "mailto:info@gatewayarmenia.org",
      text: "info@gatewayarmenia.org",
    },
    {
      image: <MdOutlinePhone />,
      href: "tel:+37455212228",
      text: "+(374) 55 21 22 36",
    },
  ];

  return (
    <div className="bg-black pt-2 pb-4 max-md:px-4">
      <div className="container mx-auto flex max-md:space-y-4 md:space-x-4 max-md:flex-wrap">
        <div className="w-full md:w-1/2">
          <div className="py-4 text-2xl font-bold text-white">USA</div>
          <Card className="overflow-hidden bg-transparent border rounded-md">
            <List className="p-0 my-2">
              {listUsa &&
                listUsa.map((item, index) => {
                  return (
                    <ListItem
                      className="px-3 py-2 font-normal text-white rounded-none sm:text-lg hover:text-main"
                      key={index}
                    >
                      <ListItemPrefix>{item.image}</ListItemPrefix>
                      <a href={item.href}>{item.text}</a>
                    </ListItem>
                  );
                })}
            </List>
          </Card>
        </div>
        <div className="w-full md:w-1/2">
          <div className="py-4 text-2xl font-bold text-white">ARMENIA</div>
          <Card className="overflow-hidden bg-transparent border rounded-md">
            <List className="p-0 my-2">
              {listAm &&
                listAm.map((item, index) => {
                  return (
                    <ListItem
                      className="px-3 py-2 font-normal text-white rounded-none sm:text-lg hover:text-main"
                      key={index}
                    >
                      <ListItemPrefix>{item.image}</ListItemPrefix>
                      <a href={item.href}>{item.text}</a>
                    </ListItem>
                  );
                })}
            </List>
          </Card>
        </div>
      </div>
      <p className="mt-4 text-sm text-center text-gray-400">
        &copy; 2013&ndash;{new Date().getFullYear()} Gateway Industry. Web development by{" "}
        <a className="font-semibold" href="https://abov.am/">
          ABOV IT
        </a>
      </p>
    </div>
  );
}
