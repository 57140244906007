const BASE_URL = window.location.protocol + "//" + window.location.hostname;
const API_URL = process.env.NODE_ENV === "development" ? `${BASE_URL}:5000/api/email` : `${BASE_URL}/api/email`;
const FAILURE_NOTIFICATION = "Something went wrong, please try again later.";
const SUCCESS_NOTIFICATION = "Your message has been successfully sent.";

export default function sendEmail(e) {
  e.preventDefault();

  const form = e.target;
  const formData = new FormData(form);
  const formValues = Object.fromEntries(formData);

  return fetch(API_URL, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(formValues),
  })
    .then((response) => {
      if (!response.ok) {
        // console.log("response", response);
        throw new Error(response.status);
      }
    })
    .then((data) => {
      // console.log("data", data);
      form.reset();
      return { success: true, notification: SUCCESS_NOTIFICATION };
    })
    .catch((error) => {
      // console.log("error", error);
      return { success: false, notification: FAILURE_NOTIFICATION };
    });
}
