import { Card, CardHeader, CardBody, Typography } from "@material-tailwind/react";

import BannerImage from "./components/bannerImage";
import BannerImg from "../assets/img/homeSlider/tumo-transformed.webp";
import Img1 from "../assets/img/tumo/260724076_350313820197390_8929205389877322354_n.jpg";
import Img2 from "../assets/img/tumo/260779605_350313823530723_2628047055486516160_n.jpg";
import Img3 from "../assets/img/tumo/260995237_350313816864057_3510470050348431294_n.jpg";
import Slider from "react-slick";

export default function TumoKidsTransportation() {
  const settings = {
    autoplay: true,
    autoplaySpeed: 2000,
    dots: false,
    fade: true,
    infinite: true,
    slidesToScroll: 1,
    slidesToShow: 1,
    speed: 500,
  };

  const imageList = [
    {
      image: Img1,
      title: "Tumo Kids Image",
    },
    {
      image: Img2,
      title: "Tumo Kids Image",
    },
    {
      image: Img3,
      title: "Tumo Kids Image",
    },
  ];

  const list = [
    {
      text: "The TUMO Center for Creative Technologies is a free-of-charge educational program offering workshops, project labs and self-learning activities in the areas of animation, music, writing, filmmaking, programming, robotics, etc.",
    },
    {
      text: "The TUMO Center in Gyumri is an oasis of innovation and youth empowerment in the city of Gyumri and the Shirak region at large. Starting in March 2021, Gateway Industry has been collaborating with TUMO Gyumri to provide youth in the various villages of the Shirak region with the opportunity to participate in TUMO's educational programs. Gateway Industry collaborates with TUMO Gyumri and the benefactor families to arrange the transportation services to and from TUMO Gyumri.",
    },
    {
      text: "Without the transportation services funded by Gateway Industry, only a limited number of children who live in close proximity to the center would have access to TUMO's educational programs. This project has been welcomed with great enthusiasm by the participating youth and their families in the Shirak region.",
    },
    {
      text: "Currently, 71 children participate in the Project, but this number is gradually increasing.",
    },
  ];

  return (
    <>
      <BannerImage image={BannerImg} />
      <div className="max-w-screen-xl py-24 mx-auto max-xl:px-6">
        <Card className="static !z-0 flex-row w-full shadow-none bg-transparent p-0 m-0 max-md:flex-col">
          <CardHeader
            shadow={false}
            floated={false}
            className="static z-0 h-full m-0 bg-transparent rounded-r-none md:w-1/2 shrink-0"
          >
            <Slider {...settings} className="h-full p-0 m-0">
              {imageList &&
                imageList.map((item, index) => {
                  return (
                    <div key={index}>
                      <img src={item.image} alt="" className="h-full" />
                    </div>
                  );
                })}
            </Slider>
          </CardHeader>
          <CardBody className="flex flex-col justify-center">
            <Typography variant="h4" color="blue-gray" className=" text-main">
              Tumo Kids Transportation
            </Typography>
            {list &&
              list.map((item, index) => {
                return (
                  <Typography color="gray" className="mt-2 font-normal text-black/60">
                    {item.text}
                  </Typography>
                );
              })}
          </CardBody>
        </Card>
      </div>
    </>
  );
}
