import { useState } from "react";
import { MdCheckCircle, MdOutlineDisabledByDefault } from "react-icons/md";
import {
  Alert,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Dialog,
  DialogBody,
  DialogHeader,
  IconButton,
} from "@material-tailwind/react";

import ContactForm from "../../Pages/components/contactForm";

export default function CardSponsorship({ data, cardCSS }) {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(!open);

  return (
    <>
      <Card
        className={`group cursor-pointer hover:scale-[1.02] p-4 md:py-6 bg-gradient-to-b from-main from-15% via-[#450b17] via-45% to-black to-90% ${cardCSS}`}
        onClick={handleOpen}
      >
        <CardHeader
          floated={false}
          shadow={false}
          color="transparent"
          className="m-0 mb-3 pb-2 text-center text-white border-b border-white/20 rounded-none"
        >
          <h4 className="font-semibold text-xl">{data.title}</h4>
          <h5 className="font-bold text-2xl mt-2">{data.donation}</h5>
        </CardHeader>
        {data.honor ? (
          <CardHeader
            floated={false}
            shadow={false}
            color="transparent"
            className="m-0 mb-4 px-4 pb-2 text-center text-white flex justify-center items-center flex-grow border-b border-white/20 rounded-none"
          >
            <h6 className="font-semibold text-center text-lg leading-snug">{data.honor}</h6>
          </CardHeader>
        ) : (
          ""
        )}
        <CardBody className={`p-0 text-white ${!data.honor ? "flex-grow" : ""}`}>
          <ul className="flex flex-col gap-2 md:mx-4">
            {data.benefits.map((benefit, index) => {
              return (
                <li key={index} className="flex items-center gap-2 mb-2 leading-snug">
                  <MdCheckCircle className="shrink-0" />
                  {benefit}
                </li>
              );
            })}
          </ul>
        </CardBody>
        <CardFooter className="mt-8 p-0">
          <button className="block w-full font-semibold bg-white text-black text-center py-4 rounded-md">
            Pledge Sponsorship
          </button>
        </CardFooter>
      </Card>
      <Dialog open={open} handler={handleOpen} className="max-lg:h-[92%] max-lg:overflow-y-scroll">
        <DialogHeader className="justify-between">
          <h4 className="text-xl lg:text-2xl lg:ml-4">
            {data.title} &ndash; {data.donation}
          </h4>
          <IconButton variant="text" color="gray" size="sm" onClick={handleOpen}>
            <MdOutlineDisabledByDefault size="2rem" />
          </IconButton>
        </DialogHeader>
        <DialogBody divider className="bg-gray-50">
          <div className="lg:grid lg:grid-cols-2 lg:gap-4">
            <div className="flex flex-col lg:px-4">
              {data.honor ? (
                <p className="mb-4 lg:mb-8 text-lg lg:text-xl text-main font-bold leading-tight">{data.honor}</p>
              ) : (
                ""
              )}
              <ul className="flex flex-col flex-grow lg:gap-2 font-semibold">
                {data.benefits.map((benefit, index) => {
                  return (
                    <li key={index} className="flex items-center gap-2 mb-2">
                      <MdCheckCircle className="shrink-0" />
                      {benefit}
                    </li>
                  );
                })}
              </ul>
              <Alert variant="outlined" color="green" className="lg:mt-4 max-lg:mb-2 font-medium">
                We will contact you by phone or email to review payment options and arrange the remittance.
              </Alert>
            </div>
            <div className="bg-white py-4 rounded-lg">
              <ContactForm
                emailSubject="Gala Sponsorship Pledge"
                initialMessageValue={`Gateway Industry 10th Anniversary Gala: I pledge to sponsor the ${data.title} package for ${data.donation}.`}
              />
            </div>
          </div>
        </DialogBody>
      </Dialog>
    </>
  );
}
