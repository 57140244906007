import HomeImage from "./components/homeImage";
import HomeSlider from "./components/homeSlider";
import Mission from "./components/mission";
import ProjectsCount from "./components/projectsCount";

export default function Home() {
  return (
    <>
      <HomeSlider />
      <ProjectsCount />
      <Mission />
      <HomeImage />
    </>
  );
}
