import { Card, CardHeader, CardBody, Typography } from "@material-tailwind/react";

import ImageAraqsya from "../assets/img/team/araqsya.png";
import ImageArmen from "../assets/img/team/armen.jpg";
import ImageNare from "../assets/img/team/nare.png";
import ImageRobert from "../assets/img/team/robert.jpg";
import ImageSarkis from "../assets/img/team/sarkis.png";

export default function OurTeam() {
  return (
    <div className="w-full py-24 max-xl:px-6">
      <div className="max-w-screen-xl mx-auto">
        <Typography className="text-xl font-bold sm:text-3xl text-main">Our Team</Typography>
        <div className="flex w-full py-12 lg:space-x-4 max-lg:space-y-6 max-lg:items-center max-lg:flex-col xl:space-x-6">
          <Card className=" w-fit lg:w-1/3">
            <CardHeader floated={false}>
              <img src={ImageSarkis} alt="" />
            </CardHeader>
            <CardBody className="text-center">
              <Typography variant="h4" color="blue-gray" className="mb-2 text-main">
                Sarkis Kiramijyan
              </Typography>
            </CardBody>
          </Card>
          <Card className="w-fit lg:w-1/3">
            <CardHeader floated={false}>
              <img src={ImageArmen} alt="" />
            </CardHeader>
            <CardBody className="text-center">
              <Typography variant="h4" color="blue-gray" className="mb-2 text-main">
                Armen Kiramijyan
              </Typography>
            </CardBody>
          </Card>
          <Card className="w-fit lg:w-1/3">
            <CardHeader floated={false}>
              <img src={ImageRobert} alt="" />
            </CardHeader>
            <CardBody className="text-center">
              <Typography variant="h4" color="blue-gray" className="mb-2 text-main">
                Robert Tingilian
              </Typography>
            </CardBody>
          </Card>
        </div>
        <div className="flex w-full py-12 lg:space-x-4 max-lg:space-y-6 max-lg:items-center max-lg:flex-col xl:space-x-6">
          <Card className="w-fit lg:w-1/3">
            <CardHeader floated={false}>
              <img src={ImageNare} alt="" />
            </CardHeader>
            <CardBody className="text-center">
              <Typography variant="h4" color="blue-gray" className="mb-2 text-main">
                Nare Harutyunyan
              </Typography>
            </CardBody>
          </Card>
          <Card className="w-fit lg:w-1/3">
            <CardHeader floated={false}>
              <img src={ImageAraqsya} alt="" />
            </CardHeader>
            <CardBody className="text-center">
              <Typography variant="h4" color="blue-gray" className="mb-2 text-main">
                Araqsya Arakelyan
              </Typography>
            </CardBody>
          </Card>
          <Card className="opacity-0 w-fit lg:w-1/3"></Card>
        </div>
      </div>
    </div>
  );
}
