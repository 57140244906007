import { Typography } from "@material-tailwind/react";

export default function Mission() {
  return (
    <div className="relative w-full py-12 max-xl:px-6 bg-black/10bg-black/10 sm:py-36 -top-2">
      <div className="max-w-screen-lg mx-auto">
        <Typography className="text-2xl font-bold text-center text-main">Our Mission</Typography>
        <Typography className="mt-2 font-normal text-center text-black sm:text-lg">
          Gateway Industry Inc. is an association of like-minded professionals who have come together with the
          determination of improving their families and communities in the United States and in Armenia. The members of
          the association strongly believe that a unified effort produces exponential effects in any work that is
          undertaken.
        </Typography>
      </div>
    </div>
  );
}
