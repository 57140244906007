import React, { useState, useEffect } from "react";
import axios from "axios";
import { Typography } from "@material-tailwind/react";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

const BASE_URL = window.location.protocol + "//" + window.location.hostname;
const API_URL =
  process.env.NODE_ENV === "development"
    ? `${BASE_URL}:5000/api/gallery`
    : `${BASE_URL}/api/gallery`;

export default function Gallery() {
  const [images, setImages] = useState([]);
  const [index, setIndex] = useState(-1);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    axios
      .get(API_URL)
      .then((response) => {
        setImages(response.data.resources);
      })
      .catch((error) => {
        console.error("Error fetching images", error);
      });
  }, []);

  const openLightbox = (clickedIndex) => {
    setIndex(clickedIndex);
    setOpen(true);
  };

  const slides = images.map((item) => {
    return { src: item.url };
  });

  return (
    <>
      <div className="container py-12 mx-auto">
        <Typography className="text-xl font-bold text-center sm:text-3xl text-main">
          Gateway Industry 10th Anniversary Gala October 29, 2023
        </Typography>
        <div className="grid grid-cols-1 gap-4 pt-12 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
          {images.map((item, index) => {
            console.log(item.url);
            const splitIndex = item.url.indexOf("/upload/");
            if (splitIndex === -1) {
              console.log("not found");
              return null;
            }

            const modifiedUrl =
              item.url.slice(0, splitIndex + 8) +
              "c_auto,g_auto,w_400,h_300/" +
              item.url.slice(splitIndex + 8);

            return (
              <div key={index} className="flex items-center justify-center">
                <img
                  alt=""
                  className="rounded-md cursor-pointer"
                  onClick={() => openLightbox(index)}
                  src={modifiedUrl}
                />
              </div>
            );
          })}
        </div>
      </div>
      <Lightbox
        close={() => {
          setIndex(-1);
          setOpen(false);
        }}
        controller={{ closeOnBackdropClick: true }}
        index={index}
        open={open}
        slides={slides}
      />
    </>
  );
}
